<template>
  <div v-if="rootStore.page_background_color"
       class="min-h-screen h-full" :style="{backgroundColor: `${rootStore.page_background_color}`}">
    <TsHeader data-testid="header" />

    <div>
      <slot />
    </div>

   <TsProductActions v-if="runtimeConfig.public.featureFlags.enableCTAAddToTrolleyBuyNow" />

   <TsProductActionsv2
      v-else-if="runtimeConfig.public.featureFlags.enableCTACollectionDelivery"
    />

    <TsFooter data-testid="footer" />
  </div>
</template>

<script lang="ts" setup>
const runtimeConfig = useRuntimeConfig();

const rootStore = useRootStore();
</script>
